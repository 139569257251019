<template>
  <div class="app-container">
    <div class="toolWrap">
      <el-select v-model="form.dateType" @change="setSelect">
        <el-option :label="'按日统计'" :value="'date'"></el-option>
        <el-option :label="'按月统计'" :value="'month'"></el-option>
        <el-option :label="'按年统计'" :value="'year'"></el-option>
      </el-select>
      <el-date-picker
        v-model="daterange"
        :type="rangeType"
        style="margin: 0 10px"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="getDaterange"
      ></el-date-picker>
      <el-button
        type="success"
        icon="el-icon-search"
        @click="getDataList"
      ></el-button>
    </div>
    <el-table
      ref="table"
      :data="listData"
      border
      show-summary
      highlight-current-row
      row-key="id"
    >
      <el-table-column
        prop="dateTime"
        label="日期"
        width="180"
      ></el-table-column>
      <el-table-column prop="clinchNumber" label="成交笔数"></el-table-column>
      <el-table-column
        prop="commodityWeight"
        label="商品重量"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="transactionAmount"
        label="交易金额"
        width="180"
      ></el-table-column>

      <!-- <el-table-column label="物业缴费">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.propertyCostsStatus==1?'success':'danger'"
          >{{scope.row.propertyCostsStatus |payStatusFilter}}</el-tag>
        </template>
      </el-table-column>-->
    </el-table>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      listData: [],
      rangeType: "daterange",
      daterange: "",
      form: {
        dateType: "date",
        endDate: "",
        startDate: "",
        currentPage: 1,
        pageSize: 15,
        apiName: "/statistics/transactionAnalysis",
      },
    };
  },
  filters: {
    StatusFilter(val) {
      const statusMap = { 0: "合同期内", 1: "合同过期" };
      return statusMap[val];
    },
    payStatusFilter(val) {
      const statusMap = { 0: "未缴费", 1: "已缴费" };
      return statusMap[val];
    },
  },

  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      await api.common.list(this.form).then((res) => {
        this.listData = res.data.data;
        console.log(this.listData);
      });
    },

    setSelect(e) {
      this.form.dateType = e;
      this.rangeType = e + "range";
      console.log(this.rangeType);
    },
    getDaterange(e) {
      console.log(e);
    },

    setPayItem(e, index) {
      let stallObj = this.stallList.find((item) => item.id == e);

      this.form.merchantContractStall[index].rentMoney = stallObj.stallRent;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.toolWrap {
  margin-bottom: 20px;
}
.formdate {
  width: 220px;
}
.partInput {
  width: 210px;
  margin-right: 10px;
}
.radioWidth {
  width: 240px;
}
</style>
